import "./Styles/Footer.css"

function Footer() {
    return (
        <footer className="Footer">
            GROUP ALLIANCE  -  {new Date().getFullYear()}
        </footer>
    )
}

export default Footer